@import "../common/colors.scss";

.download-container {
  .download-inner-container {
    display: flex;
    flex-flow: column;
    h2 {
      font-size: 15px;
      color: $white;
      font-weight: 400;
    }
    .download-for-windows {
      cursor: pointer;
      margin-right: 2rem;
    }
    .download-for-android {
      cursor: pointer;
    }
  }
}
@media (max-width: 500px) {
  .download-container {
    margin-left: 1rem;
    margin-right: 1rem;
    .download-inner-container {
      h2 {
        text-align: center;
      }
    }
  }
}

@media (max-width: 430px) {
  .download-container {
    .download-inner-container {
      flex-flow: column;
      align-items: center;
      .download-for-windows {
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }
  }
}
