@import "../common/colors.scss";

.tutorial-container {
  margin: 0 1.5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  .tutorial-inner-container {
    margin: 2rem 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    .text-container {
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      .tutorial-link {
        text-align: center;
      }
      &.on-left {
        padding-right: 5rem;
      }
      &.on-right {
        padding-left: 5rem;
      }
      ul {
        padding-left: 0;
        li {
          list-style: none;
          font-size: 16px;
          line-height: 28px;
          color: $tutorialTextColor;
          position: relative;
          padding-left: 28px;
          margin-bottom: 5px;
          font-weight: 400;
          z-index: 1;
        }
        li::before {
          position: absolute;
          left: 0;
          background-image: url(../shared/check.svg);
          width: 16px;
          height: 16px;
          content: "";
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      h3 {
        font-size: 46px;
        line-height: 60px;
        font-weight: 300;
        color: $darkGrey;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        color: $tutorialTextColor;
        margin-bottom: 22px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 32px;
      }
      max-width: 500px;
      display: flex;
    }
    .image-container {
      display: flex;
      justify-content: center;
      min-width: 614px;
    }
  }
  .tutorial-inner-container:last-child {
    margin-bottom: 6rem;
  }
}

@media (max-width: 1300px) {
  .tutorial-container {
    .tutorial-inner-container {
      .text-container {
        &.on-left {
          padding-right: 2rem;
        }
        &.on-right {
          padding-left: 2rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .tutorial-container {
    .tutorial-inner-container:first-child {
      margin-top: 10rem;
    }
    .tutorial-inner-container {
      .text-container {
        max-width: 450px;
        ul {
          li {
            font-size: 14px;
          }
        }
        h3 {
          font-size: 40px;
        }
        p {
          font-size: 14px;
        }
      }
      .image-container {
        min-width: 500px;
        max-width: 550px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .tutorial-container {
    .tutorial-inner-container {
      .text-container {
        max-width: 380px;
        ul {
          li {
            font-size: 13px;
          }
        }
        h3 {
          font-size: 38px;
        }
        p {
          font-size: 13px;
        }
      }
      .image-container {
        min-width: 450px;
        max-width: 500px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .tutorial-container {
    margin: 0;
    .tutorial-inner-container:first-child {
      margin-top: 1.5rem;
    }
    .tutorial-inner-container {
      flex-flow: column;
      .text-container {
        order: 1;
        max-width: 380px;
        &.on-left {
          padding-right: 0;
        }
        &.on-right {
          padding-left: 0;
        }
        ul {
          li {
            font-size: 16px;
          }
        }
        h3 {
          text-align: center;
          font-size: 44px;
        }
        p {
          text-align: center;
          font-size: 16px;
        }
      }
      .image-container {
        order: 2;
        min-width: 450px;
        max-width: 500px;
      }
    }
  }
}

@media (max-width: 850px) {
  .tutorial-container {
    .tutorial-inner-container:first-child {
      .image-container {
        img {
          max-width: 500px;
        }
      }
    }
    .tutorial-inner-container {
      .text-container {
        ul {
          li {
            font-size: 15px;
          }
        }
        h3 {
          font-size: 38px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .tutorial-container {
    .tutorial-inner-container:first-child {
      .image-container {
        img {
          max-width: 350px;
        }
      }
    }
    .tutorial-inner-container {
      margin: 1.5rem 0;
      .image-container {
        min-width: 300px;
        max-width: 350px;
      }
      .text-container {
        margin: 0 1rem;
        ul {
          li {
            font-size: 15px;
          }
        }
        h3 {
          font-size: 36px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .tutorial-container {
    .tutorial-inner-container:first-child {
      .image-container {
        img {
          max-width: 300px;
        }
      }
    }
    .tutorial-inner-container {
      .text-container {
        ul {
          li {
            font-size: 14px;
          }
        }
        h3 {
          font-size: 34px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
