@import "../common/colors.scss";

.contact-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  .contact-inner-container {
    margin: 3rem 0;
    min-width: 500px;
    display: flex;
    flex-flow: column;
    .contact-title {
      display: flex;
      justify-content: center;
      align-items: center;
      h3 {
        font-size: 40px;
        line-height: 60px;
        font-weight: 300;
        color: $darkGrey;
      }
    }
    .contact-inputs {
      display: flex;
      flex-flow: column;
      input:focus,
      textarea:focus {
        outline: none;
      }
      .contact-input-simple {
        display: flex;
        flex-flow: column;
        h4 {
          font-size: 15px;
          line-height: 20px;
          font-weight: 300;
          color: $darkGrey;
        }
        div {
          display: flex;
          width: 100%;
          input,
          textarea {
            width: 100%;
            border: 1px solid transparent;
            padding: 17px 20px;
            box-shadow: 0 10px 24px 0 $contactBoxShadow;
            border-radius: 30px;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 400;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
          input {
            height: 2rem;
          }
          textarea {
            height: 10rem;
            resize: none;
            overflow: auto;
          }
          .error-icon {
            position: absolute;
            background-repeat: none;
            margin-left: 440px;
            margin-top: 18px;
            width: 32px;
            height: 32px;
          }
        }
        .error-text {
          margin: 0.8rem 1.3rem;
          font-size: 14px;
          line-height: 15px;
          font-weight: 300;
          color: $lightRed;
        }
      }
    }
    .contact-submit {
      button:focus {
        outline: none;
      }
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      .submit-btn {
        min-height: 84px;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 2rem 0;
        border-radius: 30px;
        background-color: $darkBlue;
        padding: 17px 20px;
        width: 300px;
        font-size: 18px;
        font-weight: 200;
        color: $white;
        box-shadow: 0 10px 24px 0 $contactBoxShadow;
        border: 1px solid transparent;
        font-family: "Poppins", sans-serif;
        .success-checkmark {
          width: 48px;
          height: 48px;
          margin-left: 1rem;

          .check-icon {
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 50%;
            box-sizing: content-box;
            border: 4px solid $lightGreen;

            &::before {
              top: 3px;
              left: -2px;
              width: 30px;
              transform-origin: 100% 50%;
              border-radius: 100px 0 0 100px;
            }

            &::after {
              top: 0;
              left: 30px;
              width: 60px;
              transform-origin: 0 50%;
              border-radius: 0 100px 100px 0;
              animation: rotate-circle 4.25s ease-in;
            }

            &::before,
            &::after {
              content: "";
              height: 25px;
              position: absolute;
              background: transparent;
              transform: rotate(-45deg);
            }

            .icon-line {
              height: 5px;
              background-color: $lightGreen;
              display: block;
              border-radius: 2px;
              position: absolute;
              z-index: 10;

              &.line-tip {
                top: 21px;
                left: 7px;
                width: 15px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
              }

              &.line-long {
                top: 18px;
                right: 5px;
                width: 20px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
              }
            }

            .icon-circle {
              top: -4px;
              left: -4px;
              z-index: 10;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              position: absolute;
              box-sizing: content-box;
              border: 4px solid $circleGreen;
            }

            .icon-fix {
              top: 8px;
              width: 5px;
              left: 26px;
              z-index: 1;
              height: 45px;
              position: absolute;
              transform: rotate(-45deg);
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .contact-container {
    .contact-inner-container {
      min-width: 450px;
      max-width: 600px;

      .contact-inputs {
        .contact-input-simple {
          div {
            .error-icon {
              margin-left: 390px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .contact-container {
    .contact-inner-container {
      margin: 2rem 0;
      min-width: 350px;
      .contact-title {
        h3 {
          font-size: 36px;
          text-align: center;
        }
      }
      .contact-inputs {
        .contact-input-simple {
          div {
            .error-icon {
              margin-left: 300px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .contact-container {
    .contact-inner-container {
      min-width: 200px;
      max-width: 280px;
      .contact-inputs {
        .contact-input-simple {
          div {
            .error-icon {
              margin-left: 220px;
            }
          }
        }
      }
    }
  }
}
