@import "../common/colors.scss";

.no-webp .not-found-container {
  background-image: url(../shared/error.png);
}

.webp .not-found-container {
  background-image: url(../shared/error.webp);
}

.not-found-container {
  padding-top: 5rem;
  height: calc(100vh - 178px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: bottom left;
  background-repeat: no-repeat;
  .not-found-inner-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    .left-column {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      border-right: 1px solid $contactBoxShadow;
      max-width: 500px;
      .not-found-statuscode {
        font-size: 100px;
        font-weight: 600;
        letter-spacing: 2px;
        color: $tutorialTextColor;
      }
      .not-found {
        text-align: center;
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 2px;
        color: $tutorialTextColor;
      }
    }
    .right-column {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      max-width: 500px;
      .description {
        font-size: 28px;
        font-weight: 200;
        letter-spacing: 2px;
        color: $tutorialTextColor;
        text-align: justify;
      }
      .go-to-homepage {
        margin-top: 2rem;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          text-decoration: none;
          .homepage-button {
            cursor: pointer;
            background-color: $darkBlue;
            border-radius: 30px;
            color: $white;
            padding: 1rem 2rem;
            box-shadow: 0 10px 24px 0 $contactBoxShadow;
            border: 1px solid transparent;
          }
          .homepage-button:hover {
            transition: ease 0.3s;
            padding: 1.5rem 2.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .not-found-container {
    .not-found-inner-container {
      .left-column {
        .not-found-statuscode {
          font-size: 80px;
        }
        .not-found {
          font-size: 30px;
        }
      }
      .right-column {
        .description {
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .not-found-container {
    .not-found-inner-container {
      flex-flow: column;
      .left-column {
        border-right: 0px;
        border-bottom: 1px solid $contactBoxShadow;
        .not-found-statuscode {
          font-size: 60px;
        }
        .not-found {
          font-size: 25px;
        }
      }
      .right-column {
        .description {
          font-size: 20px;
        }
        .go-to-homepage {
          a {
            .homepage-button {
              padding: 0.6rem 1.6rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .not-found-container {
    background: none;
  }
}
