@import "../common/colors.scss";

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  .header-background {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    max-height: 71px;
    height: 71px;
    opacity: 0;
    background: $darkGrey;
    box-shadow: 0 5px 2px 0 $darkGrey, 0 6px 20px 0 $darkGrey;
  }
  .header-container {
    z-index: 1;
    padding: 0.5rem 0;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .mobile-left-nav-icon {
      display: none;
      #nav-icon {
        width: 35px;
        height: 27px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }

      #nav-icon span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      #nav-icon span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon span:nth-child(2) {
        top: 12px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon span:nth-child(3) {
        top: 24px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0px;
        left: 4px;
      }

      #nav-icon.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      #nav-icon.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 25px;
        left: 4px;
      }
    }
    .left-logo {
      cursor: pointer;
      z-index: 1;
      min-width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        text-decoration: none;
        margin-left: 1rem;
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .logo-text {
          color: $white;
          font-family: "Poppins", sans-serif;
          font-size: 24px;
          text-transform: capitalize;
          letter-spacing: 2px;
          font-weight: 300;
          line-height: 18px;
          margin-left: 1rem;
        }
      }
    }
    .center-nav {
      display: flex;
      align-items: center;
      nav {
        ul {
          min-height: 24px;
          display: flex;
          flex-flow: row;
          padding: 0;
          li {
            margin-left: 1rem;
            list-style: none;
            a {
              color: $white;
              font-size: 15px;
              text-transform: capitalize;
              font-weight: 400;
              display: inline-block;
              padding: 0px;
              font-family: "Poppins", sans-serif;
              position: relative;
              text-transform: capitalize;
              text-decoration: none;
            }
            a:hover {
              font-size: 16px;
              transition: 0.3s ease-in-out;
            }
          }
          .download-beta {
            a {
              color: $mobileGreen;
            }
          }
        }
      }
    }
    .right-languages {
      z-index: 1;
      min-width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      p {
        color: $white;
        margin-left: 1rem;
      }
      img {
        box-shadow: 0 5px 10px 0 $darkGrey;
      }
    }
  }
  .mobile-menu {
    background: $darkGrey;
    height: 0px;
    transition: 0.12s ease-in-out;
    &.collapse {
      height: 235px;
      transition: 0.09s ease-in-out;
    }
    ul {
      display: flex;
      flex-flow: column;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        padding: 1rem;
        text-align: center;
        a {
          color: $white;
          font-size: 15px;
          text-transform: capitalize;
          font-weight: 400;
          display: inline-block;
          padding: 0px;
          font-family: "Poppins", sans-serif;
          position: relative;
          text-transform: capitalize;
          text-decoration: none;
        }
      }
      .download-beta {
        a {
          color: $mobileGreen;
        }
      }
    }
  }
}

@media (min-width: 850px) {
  header {
    .mobile-menu {
      display: none;
    }
  }
}

@media (max-width: 850px) {
  header {
    .header-background {
      max-height: 57.11px;
      height: 57.11px;
    }
    .header-container {
      padding: 0;
      .mobile-left-nav-icon {
        min-width: 15%;
        display: flex;
        padding-left: 1rem;
        justify-content: flex-start;
        align-items: center;
      }
      .left-logo {
        a {
          margin-left: 0;
        }
      }
      .center-nav {
        display: none;
      }
      .right-languages {
        min-width: 15%;
        padding-right: 1rem;
      }
    }
  }
}

@media (max-width: 500px) {
  header {
    .header-container {
      padding: 0.5rem 0;
      .mobile-left-nav-icon {
        padding-left: 0.8rem;
      }
      .left-logo {
        min-width: 200px;
        .logo {
          margin-left: 0;
        }
        .logo-text {
          display: none;
        }
      }
      .right-languages {
        padding-right: 0.8rem;
        p {
          display: none;
        }
      }
    }
  }
}
