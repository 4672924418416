@import "../common/colors.scss";

.no-webp .main-download-container {
  background-image: url(../shared/download-background.png);
}

.webp .main-download-container {
  background-image: url(../shared/download-background.webp);
}

.main-download-container {
  background-size: cover;
  background-position: center center;
  padding: 164px 0;
  background-repeat: no-repeat;
  max-height: 450px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  .main-download-inner-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 75%;
    h3 {
      font-size: 46px;
      font-weight: 300;
      color: $white;
      line-height: 60px;
      margin-right: 1rem;
    }
  }
}

@media (max-width: 1000px) {
  .main-download-container {
    height: auto;
    background-size: cover;
  }
}

@media (max-width: 850px) {
  .main-download-container {
    padding: 120px 0;
    .main-download-inner-container {
      h3 {
        text-align: center;
        font-size: 42px;
      }
    }
  }
}

@media (max-width: 500px) {
  .main-download-container {
    .main-download-inner-container {
      h3 {
        font-size: 36px;
      }
    }
  }
}

@media (max-width: 400px) {
  .main-download-container {
    padding: 110px 0;
    .main-download-inner-container {
      h3 {
        font-size: 33px;
      }
    }
  }
}
