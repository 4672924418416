$white: #FFFFFF;
$darkGrey: #2c2c2c;
$darkBlue: #455d87;
$lightGrey: #c7c7c7;
$contactBoxShadow: hsla(210, 8%, 54%, 0.16);
$lightRed: #fb6666;
$lightGreen: #4caf50;
$circleGreen: rgba(76, 175, 80, 0.5);
$tutorialTextColor: #727272;
$steelblue: steelblue;
$mobileGreen: #23bb74;
$warningOrange: #E6AF2F;