@import "../common/colors.scss";

.help-container {
  margin: 0 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .help-inner-container {
    margin: 3rem 0;
    max-width: 720px;
    display: flex;
    flex-flow: column;
    color: $white;
    background-color: $warningOrange;
    border-radius: 10px;
    padding: 2rem;
    .help-text {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: justify;
    }
    .question {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 1rem;
      text-align: justify;
      text-decoration: underline;
    }
    .answer {
      font-size: 16px;
      text-align: justify;
      margin-bottom: 0.8rem;
      .exe-name {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1000px) {
  .help-container {
    .help-inner-container {
      .help-text {
        font-size: 22px;
      }
      .question {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0.5rem;
      }
      .answer {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 340px) {
    .help-container {
      .help-inner-container {
        .help-text {
          font-size: 20px;
        }
        .question {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }
        .answer {
          font-size: 14px;
        }
      }
    }
  }
  
