@import "../common/colors.scss";

.no-webp .slider-container {
  background-image: url(../shared/header-background.png);
}

.webp .slider-container {
  background-image: url(../shared/header-background.webp);
}

.slider-container {
  max-width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 900px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;

  .slider-inner-container {
    height: 900px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin-top: 110px;
    .slider-left {
      flex-flow: column;
      margin-right: 10rem;
      .product-name {
        color: $white;
        font-size: 60px;
        text-transform: capitalize;
        letter-spacing: 2px;
        font-weight: 300;
        line-height: 74px;
      }
      .product-short-description {
        font-size: 20px;
        font-weight: 400;
        color: $white;
        margin-bottom: 48px;
        margin-top: 16px;
      }
    }
    .slider-right {
      img {
        max-width: 900px;
      }
    }
  }
}

@media (max-width: 1650px) {
  .slider-container {
    .slider-inner-container {
      margin-top: 0;
      .slider-left {
        margin-right: 6rem;
        .product-name {
          font-size: 54px;
        }
        .product-short-description {
          font-size: 18px;
        }
      }
      .slider-right {
        img {
          max-width: 750px;
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  .slider-container {
    .slider-inner-container {
      .slider-left {
        margin-right: 4rem;
        .product-name {
          font-size: 46px;
        }
        .product-short-description {
          font-size: 15px;
        }
      }
      .slider-right {
        img {
          max-width: 650px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .slider-container {
    .slider-inner-container {
      margin-top: 20rem;
      flex-flow: column;
      .slider-left {
        width: 100%;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .product-name {
          font-size: 42px;
        }
        .product-short-description {
          font-size: 14px;
        }
      }
      .slider-right {
        width: 100%;
        margin-top: 5rem;
        img {
          margin-left: 5rem;
          max-width: 800px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .slider-container {
    .slider-inner-container {
      margin-top: 8rem;
    }
    height: auto;
    background-size: cover;
  }
}

@media (max-width: 900px) {
  .slider-container {
    .slider-inner-container {
      margin-top: 7rem;
      .slider-right {
        img {
          margin-left: 3rem;
          max-width: 600px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .slider-container {
    .slider-inner-container {
      margin-top: 3rem;
      .slider-left {
        .product-name {
          font-size: 38px;
        }
        .product-short-description {
          font-size: 13px;
        }
      }
      .slider-right {
        img {
          max-width: 450px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .slider-container {
    .slider-inner-container {
      margin-top: 0;
      .slider-right {
        img {
          margin-left: 1.5rem;
          max-width: 400px;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .slider-container {
    .slider-inner-container {
      .slider-left {
        .product-name {
          font-size: 35px;
        }
        .product-short-description {
          font-size: 14px;
        }
      }
      .slider-right {
        display: flex;
        justify-content: center;
        img {
          padding-left: 0.5rem;
          max-width: 300px;
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .slider-container {
    .slider-inner-container {
      margin-top: 0;
      .slider-right {
        img {
          max-width: 275px;
        }
      }
    }
  }
}
