@import "../common/colors.scss";

.static-documents-container {
  min-height: 750px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  .static-documents-inner-container {
    margin-top: 10rem;
    margin-bottom: 5rem;
    max-width: 50%;
    color: $tutorialTextColor;
    line-height: 1.5rem;
    .title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 2.5rem;
      color: $darkGrey;
    }
    .paragraph {
      display: flex;
      flex-flow: column;
      margin-top: 2rem;
      .paragraph-title {
        font-size: 1.5rem;
        color: $darkGrey;
        margin-bottom: 1rem;
      }
      .paragraph-content {
        text-align: justify;
        color: $tutorialTextColor;
      }
    }
  }
}

@media (max-width: 1600px) {
  .static-documents-container {
    .static-documents-inner-container {
      max-width: 75%;
    }
  }
}

@media (max-width: 500px) {
  .static-documents-container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    .static-documents-inner-container {
      margin-top: 7.5rem;
      max-width: 100%;
      .title {
        line-height: 2rem;
      }
      .paragraph {
        .paragraph-title {
          line-height: 2rem;
        }
      }
    }
  }
}
