.no-webp .tutorial-container {
  &.how-to-setup {
    background-image: url(../shared/error.png);
  }
}

.webp .tutorial-container {
  &.how-to-setup {
    background-image: url(../shared/error.webp);
  }
}

.tutorial-container {
  &.how-to-setup {
    background-position: bottom left;
    background-repeat: no-repeat;
  }
}

@media (max-width: 850px) {
  .tutorial-container {
    .tutorial-inner-container {
      .image-container {
        img {
          max-width: 500px;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .tutorial-container {
    .tutorial-inner-container {
      .image-container {
        img {
          max-width: 300px;
        }
      }
    }
  }
}
