@import "../common/colors.scss";

.footer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  background-color: $darkGrey;
  box-shadow: 0 5px 10px 0 $darkGrey, 0 6px 20px 0 $darkGrey;
  .footer-container {
    padding: 1rem 0;
    font-size: 13px;
    text-align: center;
    color: $white;
    .privacy-policy {
      color: $steelblue;
      font-weight: 400;
    }
    .terms-and-conditions {
      color: $steelblue;
      font-weight: 400;
    }
    .copy-right {
      color: $lightGrey;
      font-weight: 400;
      .company {
        color: $steelblue;
      }
    }
  }
}
