@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700&display=swap");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  .App {
    .content {
      min-height: calc(100vh - 178px);
    }
  }
}
