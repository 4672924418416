@import "../common/colors.scss";
.tutorial-inner-container {
  margin: 2rem 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  .text-container {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    &.on-left {
      padding-right: 5rem;
    }
    &.on-right {
      padding-left: 5rem;
    }
    ul {
      padding-left: 0;
      li {
        list-style: none;
        font-size: 16px;
        line-height: 28px;
        color: $tutorialTextColor;
        position: relative;
        padding-left: 28px;
        margin-bottom: 5px;
        font-weight: 400;
        z-index: 1;
      }
      li::before {
        position: absolute;
        left: 0;
        background-image: url(../shared/check.svg);
        width: 16px;
        height: 16px;
        content: "";
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    h3 {
      font-size: 46px;
      line-height: 60px;
      font-weight: 300;
      color: $darkGrey;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: $tutorialTextColor;
      margin-bottom: 22px;
      line-height: 30px;
      margin-top: 20px;
      margin-bottom: 32px;
    }
    max-width: 500px;
    display: flex;
  }
  .image-container {
    display: flex;
    justify-content: center;
    min-width: 614px;
  }
}
.tutorial-inner-container:last-child {
  margin-bottom: 6rem;
}
